<template>
    
       
    <TopHeader/>
    <Menu/>
  
    <slot/>
    
    <Footer/>

</template>
<script>
// import TopHeader from '@/components/TopHeader.vue';
import Menu from '@/components/header/Menu.vue';

import TopHeader from '@/components/header/TopHeader.vue';
import Footer from '@/components/Footer.vue';
import { useRoute } from 'vue-router';
export default {
  name: 'MainLayout',
  components: {
    Menu,
    TopHeader,
    Footer,
  
  },

  // setup() {
  //   const route = useRoute();
  //   // const isHomePage = route.path === '/';
  //   const isHomePage = route.name === "home";

  //   return {
  //     isHomePage,
  //   };
  // }


}
</script>

<style>
.main {
  /* position: relative;
  isolation: isolate; */
}


</style>