import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTelInput from 'vue-tel-input'
import "@/assets/sass/app.scss";


createApp(App)
    .use(store)
    .use(router)
    .use(VueTelInput, {
        required: true,
        enabledFlags: true,
        defaultCountry: 'fr',
        validCharactersOnly: true,
        dynamicPlaceholder: true,
        enabledCountryCode: true,
        disabledFetchingCountry: false,
        inputOptions: {
            showDialCode: true,
            tabindex: 0
        }
    })
    .mount("#app");
