<template>
  <component :is="$route.meta.layout">
    <router-view />
  </component>
</template>
  

<!-- <script>
export default {
  name: 'App',
  head() {
    return {
      title: 'E-eee', // Set your page title
      link: [
        {    
          rel: 'icon',
          href: 'http://localhost:8080/img/edocng-logo.c6636600.webp', // Specify the path to your favicon
        },
      ],
    };
  },
};
</script> -->
