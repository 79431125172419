<template>
    <main>
        <slot/>
    </main>
</template>
<script>

export default {
  name: 'BlackLayout',
  components: {}
}
</script>